import React, { useRef, useState, useEffect } from 'react'
import '../App.css';
import axios from 'axios'
import Carousel from 'react-tiny-slider'
import Story from './Story/Story'
import _ from 'lodash'
import { baseUrl } from '../App';

const Stories = () => {
    console.log('Stories()')
    const [error, setError] = useState(null)
    const [stories, setStories] = useState([])

    const carousel = useRef(null)
    const microSeconds = 60000

    useEffect(() => {
        console.log('useEffect()')
        const getStories = () => {
            console.log('getStories()')
            return axios
                .get(baseUrl)
                .then(response => {
                    console.log('Fulfilled call to API')
                    const stories_latest = response.data.stories
                    console.log(`${stories.length} vs ${stories_latest.length}`)
                    if (!_.isEqual(stories, stories_latest)) {
                        console.log('stories UNEQUAL to stories_latest')
                        setStories(stories_latest)
                    }
                })
                .catch(error => {
                    setError(error)
                })
        }
        getStories()

        const interval = setInterval(() => {
            console.log(`Runs every ${microSeconds / 1000} seconds`)
            getStories()
        }, microSeconds)

        return () => clearInterval(interval)
    }, [stories])

    if (error) {
        return (
            <div className="text-white"><h1>Error: {error.message}</h1></div>
        )
    }
    else {
        if (stories.length === 0) {
            return (
                <></>
            )
        }

        // Development optie:
        // return (
        //     <div key={Math.random()}>
        //         <Carousel
        //             items={3}
        //             swipeAngle={false}
        //             speed={800}
        //             mouseDrag={true}
        //             ref={carousel}
        //             controls={true}
        //             nav={false}
        //             gutter={20}
        //         >
        //             {stories.map(story => {
        //                 return <Story key={story.id} story={story} />
        //             })}
        //         </Carousel>
        //     </div>
        // )

        // Productie optie:
        return (
            <Carousel
                items={4}
                swipeAngle={false}
                speed={1000}
                mouseDrag={false}
                ref={carousel}
                controls={false}
                nav={false}
                gutter={29}
                autoplay={true}
                autoplayTimeout={7000}
                autoplayButtonOutput={false}
            >
                {stories.map(story => {
                    return <Story key={story.id} story={story} />
                })}
            </Carousel>
        )
    }
}

export default Stories;
